<template>
    <v-dialog max-width="400px" v-model="showDialog">
        <v-card>
            <v-card-title>Crear Grupo</v-card-title>
            <v-card-text>
                <v-text-field v-model="storegroup.name" label="Nombre"></v-text-field>
                <v-text-field v-model="storegroup.logo_url" label="Logo url"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="!storegroup.name" @click="sendForm" color="success">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            storegroup:{},
            showDialog: false,
        }
    },
    mounted(){
        this.$parent.$on('openDialogCreateStoregroup', () => {
            this.storegroup = {
                name: undefined,
                logo_url: undefined,
            };
            this.showDialog = true;
        });
    },
    methods:{
        sendForm(){
            var vm = this;
            this.axios({
                url: 'admin/store_groups',
                method: 'POST',
                data: this.storegroup,
            }).then( () => {
                vm.$emit('success');
                vm.showDialog = false;
            })
        }
    }
}
</script>