<template>
    <div>
        <v-app>
            <v-card>
                <v-card-title>
                    Grupos de Stores
                    <v-spacer></v-spacer>
                    <v-btn class @click="openDialogCreateStoregroup" text icon color="success">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn class @click="fetchData" text icon color="blue">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-card class="d-flex ">
                        <v-text-field
                        v-model="search"
                        clearable
                        label="Búsqueda"
                        single-line
                        hide-details
                        outlined
                        class="ml-2 mr-2"
                        ></v-text-field>
                        <button @click="fetchData" :class="'btn btn-primary'">Buscar</button>
                    </v-card>
                </v-card-text>
                <v-card-text>
                    <v-data-table
                        :items="tableData"
                        :headers="headers"
                        no-results-text="No hay resultados disponibles"
                        no-data-text="No hay datos disponibles"
                        loading-text="Cargando datos..."
                        :search="search"
                    >
                        <template v-slot:item.logo_url="{ item }">
                            <td class="text-center">
                                <span v-if="item.logo_url">{{item.logo_url}}</span>
                                <span class="font-italic font-weight-thin" v-else>Sin asignar</span>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-app>
        <dialog-create-storegroup @success="fetchData"></dialog-create-storegroup>
    </div>
</template>

<script>
import DialogCreateStoregroup from '@/views/oms/Admin/DialogCreateStoregroup.vue';
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    components:{
        DialogCreateStoregroup
    },
    data(){
        return{
            search: '',
            tableData: [],
            headers: [{text:'Nombre', value:'name'},{text:'Logo', value:'logo_url'}]
        }
    },
    methods:{
        fetchData(){
            var vm = this;
            this.axios({
                url: 'admin/store_groups',
                method: 'GET'
            }).then( response => {
                vm.tableData = response.data;
            }).catch( error => {
                console.log(error);
            })
        },
        openDialogCreateStoregroup(){
            this.$emit('openDialogCreateStoregroup');
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Grupos de Stores", route: "/admin/store-groups" },
        ]);
    },
    mounted(){
        this.fetchData();
    }
}
</script>